import { call, put, all, takeLatest } from 'redux-saga/effects';
import { get } from 'loadsh';
import { toast } from 'react-toastify';
import * as actions from './actions';
import * as types from '../types';
import axios from '../../../services/axios';
import history from '../../../services/history';

function* loginRequest({ payload }) {
   try {
      const response = yield call(axios.post, '/auth', payload);
      yield put(actions.loginSuccess({ ...response.data }));
      toast.success('Login realizado com sucesso');

      axios.defaults.headers.Authorization = `Bearer ${response.data.token}`;
      if (payload.prevPath) {
         history.push(payload.prevPath)
      } else {
         history.push('/')
      }

   } catch (err) {
      toast.error('Usuário ou senha inválidos.');
      yield put(actions.loginFailure());
   }
}

function persistRehydrate({ payload }) {
   const token = get(payload, 'auth.token', '');
   if (!token) return;
   axios.defaults.headers.Authorization = `Bearer ${token}`;
}



function* registerRequest({ payload }) {
   const { id, name, lastname, email, password } = payload;

   try {
      if (id) {
         yield call(axios.patch, '/users', {
            email,
            name,
            lastname,
            password: password || undefined
         })

         toast.success('Conta alterada com sucesso');
         yield put(actions.registerUpdatedSuccess({ name, email, password }))
      } else {
         const user = yield call(axios.post, '/users', {
            email,
            name,
            lastname,
            password
         })


         toast.success('Verifique sua caixa de e-mail');
         yield put(actions.registerCreateddSuccess({ name, email, creationToken: user.data.creationToken, codeType: 'Creation' }));
         history.push('/confirmemail')
      }
   } catch (e) {
      console.log(e)
      console.log(e.response.data.message)
      let error = get(e, 'response.data.message', '');
      const status = get(e, 'response.status', 0);

      if (status === 401) {
         toast.error('Você precisa fazer o login novamente');
         yield put(actions.loginFailure());
         return history.push('/login')
      }

      if (error.length > 0) {
         toast.error(translateError(error));
      } else {
         toast.error('Error desconhecido');
      }
      yield put(actions.registerFailure());
   }


}

const translateError = (error) => {
   const translations = {
      'User already exists': 'Email já cadastrado',
      'User not found': 'Usuário não encontrado',
      'Invalid password': 'Senha inválida',
   };

   return translations[error] || 'Erro desconhecido';
};

function* verifyRegisterCodeRequest({ payload }) {
   try {
      const email = payload.email;
      const code = payload.code;
      const name = payload.name;
      const persistData = localStorage.getItem('persist:TRAINING-LOG');


      if (!persistData) return
      
      const parsedData = JSON.parse(persistData);

      const auth = JSON.parse(parsedData.auth);
      const securityToken = auth.creationToken;

      const responseData = yield call(axios.post, '/userVerification/show', {
         email,
         securityToken,
      });
      
      const userVerification = responseData.data;

      const user = yield call(axios.post, '/userVerification/verifyCreateAccountCode', {
         email,
         code
      })


      toast.success('Usuário criado com sucesso');
      yield put(actions.registerCreateddSuccess({ name, email }));
      yield put(actions.loginRequest({ email, password: userVerification.password }));

   } catch (err) {
      toast.error('Código inválido');
      yield put(actions.registerFailure());
   }

}

function* verifyRecoveryCodeRequest({ payload }) {
   try {
      const email = payload.email;
      const code = payload.code;

      const responseData = yield call(axios.get, `/recoverAccount/show?email=${email}`);
      const userVerification = responseData.data;

      if (userVerification.code != code) {
         yield put(actions.registerFailure({ codeType: 'Recovery' }));
         return toast.error('Código inválido')
      }

      const user = yield call(axios.post, '/recoverAccount/verifyRecoveryAccountCode', {
         email,
         code
      })


      history.push('/newpassword')
      yield put(actions.verifyRecoveryCodeSuccess({ recoveryToken: user.data.token }));
   } catch (err) {
      toast.error('Error desconhecido');
      yield put(actions.verifyRecoveryCodeFailure());
   }

}

function* resentRegisterCode({ payload }) {
   try {
      const { email } = payload;

      yield call(axios.put, '/userVerification/', {
         email
      })

      toast.success('Código reenviado com sucesso')

      yield put(actions.sendCodeSuccess({ codeType: 'Creation' }));
      console.log(2)
   } catch (err) {
      console.log(err)
      toast.error('Erro ao reenviar código');
      yield put(actions.sendCodeFailure());
   }
}

function* sendRecoveryCode({ payload }) {
   try {
      const { email } = payload;
      const isResend = payload.isResend | false;

      yield call(axios.post, '/recoverAccount/', {
         email
      })

      if (isResend) {
         toast.success('Código reenviado com sucesso')
      } else {
         toast.success('Código enviado com sucesso')
      }

      yield put(actions.sendCodeSuccess({ codeType: 'Recovery' }));
      history.push('/confirmemail')
   } catch (err) {
      toast.error('Erro ao enviar código');
      yield put(actions.sendCodeFailure());
   }
}

function* passwordUpdateRequest({ payload }) {
   try {
      const { email, password, repeatedPassword, recoveryToken } = payload;
      const token = recoveryToken

      yield call(axios.post, '/recoverAccount/updatepassword', {
         email, password, repeatedPassword, token
      })

      toast.success('Senha atualiazda com sucesso')
      yield put(actions.passwordUpdatedSuccess());
      history.push('/login')
   } catch (err) {
      toast.error('Erro ao alterar senha. Tente novamente mais tarde');
      console.log(err)
      yield put(actions.passwordUpdatedFailure());
   }
}

export default all([
   takeLatest(types.LOGIN_REQUEST, loginRequest),
   takeLatest(types.PERSIST_REHYDRATE, persistRehydrate),
   takeLatest(types.REGISTER_REQUEST, registerRequest),
   takeLatest(types.VERIFY_REGISTER_CODE_REQUEST, verifyRegisterCodeRequest),
   takeLatest(types.RESENT_REGISTER_CODE, resentRegisterCode),
   takeLatest(types.SEND_RECOVERY_CODE, sendRecoveryCode),
   takeLatest(types.VERIFY_RECOVERY_CODE_REQUEST, verifyRecoveryCodeRequest),
   takeLatest(types.PASSWORD_UDDATE_REQUEST, passwordUpdateRequest)
])