import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { toast } from "react-toastify";
import { FaXmark } from "react-icons/fa6";
import { TiPlus } from "react-icons/ti";
import { IoMdRemove } from "react-icons/io";

const Modal = ({ isOpen, onClose }) => {
  const initialExercise = useSelector((state) => state.workout.editingExercise);
  const [exercise, setExercise] = useState(
    useSelector((state) => state.workout.editingExercise)
  );

  const handleClickAddSerie = (e) => {
    e.preventDefault(); // Previne qualquer comportamento padrão
    const newSerie = {
      serie: exercise.series.length + 1,
      repetitions: "",
      weight: "",
    };

    setExercise((prevExercise) => ({
      ...prevExercise,
      series: [...prevExercise.series, newSerie],
    }));
  };

  const handleClickRemoveSerie = (e) => {
    e.preventDefault(); // Previne comportamento padrão
    if (exercise.series.length === 1)
      return toast.error("Exercício precisa ter pelo menos uma série");
    setExercise((prevExercise) => ({
      ...prevExercise,
      series: prevExercise.series.slice(0, -1),
    }));
  };

  const handleModalClose = () => {
    setExercise(initialExercise);
    onClose();
  };

  const handleClickSave = () => {
    let errorsSeries = false;
    exercise.series.map((serie, i) => {
      if (serie.repetitions <= 0 || serie.weight <= 0) errorsSeries = true;
    });

    if (errorsSeries) return toast.error(
      "Valores dos campos de séries precisam ser maior ou igual a 1"
    );

    toast.success('Exercício alterado com sucesso');
    onClose(exercise);
  };

  const handleChangeInputs = (index, field, value) => {
    setExercise((prevExercise) => {
      const updatedSeries = prevExercise.series.map((serie, i) => {
        if (i === index) {
          return { ...serie, [field]: Number(value) };
        }
        return serie;
      });
      return { ...prevExercise, series: updatedSeries };
    });
  };

  const handleLeaveInput = (index, field, value) => {
    if (value > 0) return;
    toast.error("Valor precisa ser maior ou igual a 1");
    setExercise((prevExercise) => {
      const updatedSeries = prevExercise.series.map((serie, i) => {
        if (i === index) {
          return { ...serie, [field]: Number(1) };
        }
        return serie;
      });
      return { ...prevExercise, series: updatedSeries };
    });
  };



  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="flex w-full items-center justify-center relative mb-6">
          <h1 className="font-Poppins text-2xl font-bold text-white">
            Editar o treino
          </h1>
          <FaXmark
            size={35}
            onClick={handleModalClose}
            className="text-IconsColor absolute right-0 cursor-pointer"
          />
        </div>

        <div>
          <div>
            <p className="font-Poppins text-IconsColor font-bold">Exercício:</p>
            <input
              type="text"
              value={exercise.name}
              readOnly
              className="inputs-fullScreen mb-2"
            />
          </div>
          <div className="flex justify-center mt-4 overflow-y-scroll max-h-56 scrollbar-custom w-full px-2">
            <table>
              <thead className="font-extralight">
                <tr>
                  <th className="text-center font-Poppins text-IconsColor">
                    Série
                  </th>
                  <th className="text-center font-Poppins text-IconsColor">
                    Reps
                  </th>
                  <th className="text-center font-Poppins text-IconsColor">
                    Peso
                  </th>
                </tr>
              </thead>
              <tbody>
                {exercise.series.map((serie, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        type="text"
                        inputMode="numeric"
                        value={serie.serie}
                        readOnly
                        className="inputs-menores text-center w-16"
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        inputMode="numeric"
                        value={serie.repetitions}
                        onChange={(e) =>
                          handleChangeInputs(
                            index,
                            "repetitions",
                            e.target.value
                          )
                        }
                        onBlur={(e) =>
                          handleLeaveInput(index, "repetitions", e.target.value)
                        }
                        className="inputs-menores text-center w-16 "
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        inputMode="numeric"
                        value={serie.weight}
                        onChange={(e) =>
                          handleChangeInputs(index, "weight", e.target.value)
                        }
                        className="inputs-menores text-center w-16 "
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex flex-row justify-between">
            <button
              type="button"
              className="effectButtons flex flex-row cursor-pointer font-Poppins font-bold text-blue-600 hover:text-blue-700 active:text-blue-900 mt-5"
              onClick={handleClickAddSerie}
            >
              <TiPlus size={24} className="mr-0.5 -ml-1" />
              Serie
            </button>

            <button
              type="button"
              className="effectButtons flex flex-row items-center cursosr-pointer font-Poppins font-bold text-red-600 mt-5 hover:text-red-700 active:text-red-900"
              onClick={handleClickRemoveSerie}
            >
              <IoMdRemove size={24} className="mr-0.5 -ml-1" />
              Serie
            </button>
          </div>
        </div>
        <button
          className=" effectButtons cursor-pointer bg-Laranja hover:bg-orange-700 active:bg-orange-800 py-2 px-11 text-xl text-white font-bold font-Poppins rounded-lg mt-8"
          onClick={handleClickSave}
        >
          Salvar
        </button>
      </div>
    </div>
  );
};

export default Modal;
