import { FaCircle } from "react-icons/fa";
import { FaAngleRight } from "react-icons/fa";
import { Link } from "react-router-dom";

const TrainingDayWeek = ({ Day, status, Workout, dayIndex }) => {
  return (
    <div>
      {status === 0 ? (
        <div>
          <div className="flex items-center">
            <FaCircle color="#333333" size={8} />
            <p className="font-Poppins font-bold ml-1.5 text-sm text-IconsColor">{Day}</p>
          </div>

          <div className=" border-IconsColor border-dashed border-2 p-2 rounded-xl mx-2 my-4 flex flex-row items-center">
            <p className="flex-1 font-bold text-IconsColor text-lg whitespace-nowrap ml-4 my-1">
              Dia de Descanso
            </p>
          </div>
        </div>
      ) : (
        ""
      )}
      {status === 'Pending' ? (
        <div>
          <div className="flex items-center">
            <FaCircle color="#FFC30D" size={8} />
            <p className="font-Poppins font-bold ml-1.5 text-sm text-IconsColor">{Day}</p>
          </div>

          <Link
            to={{
              pathname: `workout/${Workout._id}`,
              state: { dayIndex }
            }}
            key={Workout._id}
          >
            <div className=" border-IconsColor border-solid border-2 p-2 rounded-xl mx-2 my-4 flex flex-row items-center">
              <p className="flex-1 font-bold text-white text-lg whitespace-nowrap ml-4">
                {Workout.name}
              </p>
              <FaAngleRight size={38} className="text-IconsColor pointer" />
            </div>
          </Link>
        </div>
      ) : (
        ""
      )}
      {status === 'Done' ? (
        <div>
          <div className="flex items-center">
            <FaCircle color="#29AC4E" size={8} />
            <p className="font-Poppins font-bold ml-1.5 text-sm text-IconsColor">{Day}</p>
          </div>

          <Link
            to={{
              pathname: `workout/${Workout._id}`,
              state: { dayIndex }
            }}
            key={Workout._id}
          >
            <div className=" border-IconsColor border-solid border-2 p-2 rounded-xl mx-2 my-4 flex flex-row items-center">
              <p className="flex-1 font-bold text-white text-lg whitespace-nowrap ml-4">
                {Workout.name}
              </p>
              <FaAngleRight size={38} className="text-IconsColor pointer" />
            </div>
          </Link>
        </div>
      ) : (
        ""
      )}
      {status === 3 ? (
        <div>
          <div className="flex items-center">
            <FaCircle color="#C60F0F" size={8} />
            <p className="font-Poppins font-bold ml-1.5 text-sm text-IconsColor">{Day}</p>
          </div>

          <Link to="/route">
            <div className=" border-IconsColor border-solid border-2 p-2 rounded-xl mx-2 my-4 flex flex-row items-center">
              <p className="flex-1 font-bold text-white text-lg whitespace-nowrap ml-4">
                {Workout}
              </p>
              <FaAngleRight size={38} className="text-IconsColor pointer" />
            </div>
          </Link>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default TrainingDayWeek;
