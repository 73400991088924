
// Route
import Personal from "../../../../assets/PersonalTraining.svg";
import { Link } from "react-router-dom/cjs/react-router-dom";
import TrainingDayWeek from "./Week/TrainingDayWeek";
// Hooks
import { useState, useEffect } from "react";

//Icons
import { FaAngleRight } from "react-icons/fa6";


import axios from '../../../../services/axios';
import { useSelector } from "react-redux";

const Fixed = () => {

    const userId = useSelector(state => state.auth.user.id);
    const [workouts, setWorkouts] = useState('');
    
    const [monday, setMonday] = useState(2);
    const [tuesday, setTuesday] = useState(3);
    const [wednesday, setWednesday] = useState(1);
    const [thursday, setThursday] = useState(0);
    const [friday, setFriday] = useState(0);
    const [saturday, setSaturday] = useState(0);
    const [sunday, setSunday] = useState(0);
  
    useEffect( () => {
  
      async function getData() {
        try {
          const dataApi = await axios.get(`/workouts/user/${userId}`, userId);
          setWorkouts(dataApi.data);
          setWorkoutsDays(dataApi.data);
          
        } catch (err) {
          console.log(err)
        }
      }
  
      getData();
  
    }, []);

    const setWorkoutsDays = (workoutsD) => {
      const workoutsData = workoutsD;

      workoutsData.map(workout => {
        let days = [];
        workout.days.map(day => days.push(day.day));
        
        days.map((day, index) => {
          if (day === 0) setSunday({workout, index});
          if (day === 1) setMonday({workout, index});
          if (day === 2) setTuesday({workout, index});
          if (day === 3) setWednesday({workout, index});
          if (day === 4) setThursday({workout, index});
          if (day === 5) setFriday({workout, index});
          if (day === 6) setSaturday({workout, index}); 
          
        })
      })

     


    } 

    const workoutStatus = (workout, day) => {
      if(workout === 0 || workout === undefined) return 0;
      const filteredDay = workout.days.filter(d => d.day === day);
      return filteredDay[0].status;
    }
  
    return (
        <>

        {/* Faz a manipulação dos estados  */}
  
        {workouts.length > 0 ? (
          <div className=" font-Poppins mx-5">
            <div className=" flex flex-col items-center justify-start  font-Poppins">
              <h1 className="Title-pages mb-8">Treinos fixos</h1>
            </div>

            <div>
              <TrainingDayWeek Day="Segunda-feira" status={workoutStatus(monday.workout, 1)} Workout={monday.workout} dayIndex = {monday.index} />
              <TrainingDayWeek Day="Terça-feira" status={workoutStatus(tuesday.workout, 2)} Workout={tuesday.workout} dayIndex = {tuesday.index}/>
              <TrainingDayWeek Day="Quarta-feira" status={workoutStatus(wednesday.workout, 3)} Workout={wednesday.workout} dayIndex = {wednesday.index} />
              <TrainingDayWeek Day="Quinta-feira" status={workoutStatus(thursday.workout, 4)} Workout={thursday.workout} dayIndex = {thursday.index} />
              <TrainingDayWeek Day="Sexta-feira" status={workoutStatus(friday.workout, 5)} Workout={friday.workout} dayIndex = {friday.index} />
              <TrainingDayWeek Day="Sábado" status={workoutStatus(saturday.workout, 6)} Workout={saturday.workout} dayIndex = {saturday.index} />
              <TrainingDayWeek Day="Domingo" status={workoutStatus(sunday.workout, 0)} Workout={sunday.workout} dayIndex = {sunday.index} />
            </div>
          </div>
          
        ) 
        
        
        : (
          <div>
            <div className=" flex flex-col items-center justify-start  font-Poppins">
              <h1 className="Title-pages">Treinos fixos</h1>
              <p className=" text-Laranja text-xl my-3 ">
                Você ainda não tem treinos
              </p>
            </div>
            <div className=" flex justify-center items-center my-11 ">
              <img src={Personal} draggable="false" alt="Perosanal Trainer" />
            </div>
            <div className=" flex justify-center items-center ">
              <Link to="/createworkout">
                <button className=" pointer bg-Laranja hover:bg-LaranjaClaro py-3 px-8 text-white font-bold rounded-lg ">
                  Criar treino
                </button>
              </Link>
            </div>
          </div>
        )}
      </>
    )
}

export default Fixed;