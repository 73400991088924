import NavBar from "../../components/NavBar/NavBar";
import { Link } from "react-router-dom";

import { FaAngleRight } from "react-icons/fa6";
import { FaSearch } from "react-icons/fa";


const NewCustomer = () => {
  return (
    <>
        <NavBar/>
        <div className="container-responsive">
                <h1 className="Title-pages">Novo aluno</h1>
                <p className="text-CinzaClaro font-Poppins text-center text-sm mt-1">Pesquise pelo nome, email ou id do aluno</p>
                <div className="flex flex-row justify-center mt-5">
                  <input 
                      className="inputs-menores w-3/4 mr-0 rounded-l-md rounded-r-none px-4 font-Poppin text-sm"
                      placeholder="Pesquisar..."
                  />
                  <button className="flex bg-Laranja my-2 p-1 mt-1 w-9 items-center justify-center rounded-r-md">
                    <FaSearch size={16} className="text-white pointer" />
                  </button>
                </div>
            </div>
    </>
  )
}

export default NewCustomer