import NavBar from "../../components/NavBar/NavBar";
import WorkoutType from "../../components/Client/CreateWorkouts/WorkoutType";
import Date from "../../components/Client/CreateWorkouts/Date";
import TypeReuse from "../../components/Client/CreateWorkouts/TypeReuse";
import Name from "../../components/Client/CreateWorkouts/Name";
import { useState } from "react";
import Create from "../../components/Client/CreateWorkouts/Create";
import ReuseWorkout from "../../components/Client/CreateWorkouts/ReuseWorkout";

const CreateWorkout = () => {
  const [creationPage, setCreationPage] = useState(0);

  const handleNext = (e, n) => {
    if (n) return setCreationPage((prev) => prev + n);
    setCreationPage((prev) => prev + 1);
  };
  const handlePrevious = () => {
    setCreationPage((prev) => prev - 1);
  };

  return (
    <>
      <NavBar />
      <div className="container-reponsive h-full">
        {creationPage === 0 && (
          <WorkoutType next={handleNext} previous={handlePrevious} />
        )}
        {creationPage === 1 && (
          <TypeReuse
            next={(e, n) => handleNext(e, n)}
            previous={handlePrevious}
          />
        )}
        {creationPage === 2 && (
          <ReuseWorkout next={handleNext} previous={handlePrevious} />
        )}
        {creationPage === 3 && <Name next={handleNext} />}
        {creationPage === 4 && (
          <Date next={handleNext} previous={handlePrevious} />
        )}
        {creationPage === 5 && (
          <Create next={handleNext} previous={handlePrevious} />
        )}
      </div>
    </>
  );
};

export default CreateWorkout;
