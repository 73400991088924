import React from "react";
import { Switch } from "react-router-dom";
// eslint-disable-next-line no-unused-vars
import { toast } from "react-toastify";


//Global Pages
import Home from "../pages/Homee"
import Login from "../pages/Login";
import CreateAccount from "../pages/CreateAccount";
import RecoverPassword from "../pages/RecoverPassword";
import NewPassword from "../pages/NewPassword";
import ConfirmEmail from "../pages/ConfirmEmail";
import Error404 from "../pages/Error404";
import MyRoute from "./MyRoute";
import Profile from "../pages/Profile";

//Client Pages 
import CreateWorkout from '../pages/Client/CreateWorkout'; 
import MyWorkouts from "../pages/Client/MyWorkouts";
import Workout from "../pages/Client/Workout";

//Personal Pages
import ManageTrainee from "../pages/Personal/ManageTrainee";
import NewTrainee from "../pages/Personal/NewTrainee";
import TraineeManager from "../pages/Personal/TraineeManager";


export default function Routes() {
    return (
        <Switch>

            {/* Global */}
            <MyRoute exact path='/' component={Home}  />
            <MyRoute exact path='/login' component={Login} />
            <MyRoute exact path='/createaccount' component={CreateAccount} />
            <MyRoute exact path='/recoverpassword' component={RecoverPassword} />
            <MyRoute exact path='/confirmemail' component={ConfirmEmail} />
            <MyRoute exact path='/newpassword' component={NewPassword} />
            <MyRoute exact path="/profile" component={Profile} isClosed={true} />

            {/* Client */}
            <MyRoute exact path='/createworkout' component={CreateWorkout} isClosed={true} />
            <MyRoute exact path='/myworkouts' component={MyWorkouts} isClosed={true} />
            <MyRoute exact path='/workout/:id' component={Workout} isClosed={true} />
            
            
            {/* Personal */}
            <MyRoute exact path='/managetrainee' component={ManageTrainee} />
            <MyRoute exact path='/newtrainee' component={NewTrainee} />
            <MyRoute exact path='/traineemanager' component={TraineeManager} />
            <MyRoute path='*' component={Error404} />
            
            
        </Switch>

    );
};