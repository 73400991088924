import * as types from '../types'
import axios from '../../../services/axios';


const initialState = {
    isLoggedIn: false,
    token: false, 
    user: {}, 
    isLoading: false,
    codeType: '',
    recoveryToken: '',
    creationToken: '',
    pastPage: null,
    newProfilePicture: null
}

export default function (state = initialState, action) {
    
    switch (action.type) {
        case types.LOGIN_SUCCESS: {
            const newState = { ...state};
            newState.isLoggedIn = true
            newState.token = action.payload.token
            newState.user = action.payload.user 
            newState.isLoading = false;
            return newState;
        }
        case types.LOGIN_FAILURE: {
            delete axios.defaults.headers.Authorization;
            const newState = { ...initialState };
            newState.user = {};
            newState.isLoading = false;
            return newState;
        }
        case types.LOGIN_REQUEST: {
            const newState = { ...state };
            newState.isLoading = true;
            return newState;
        }

        case types.SET_PAST_PAGE: {
            const newState = { ...state };
            newState.pastPage = action.payload;
            return newState;
        }

        case types.SET_NEW_PROFILE_PICTURE: {
            const newState = { ...state };
            newState.newProfilePicture = action.payload;
            return newState;
        }

        case types.UPDATE_USER: {
            const newState = { ...state };
            const {name, profilePicture} = action.payload;
            if (name) newState.user.name = name;
            if (profilePicture) newState.user.profilePicture = profilePicture;
            return newState;
        }

        
        
        
        
        
        case types.REGISTER_CREATED_SUCCESS: {
            const newState = { ...state};
            newState.user.name = action.payload.name;
            newState.user.email = action.payload.email;
            newState.codeType = action.payload.codeType;
            newState.creationToken = action.payload.creationToken;
            newState.isLoading = false;
            return newState;
        }

        case types.REGISTER_FAILURE: {
            const newState = { ...state };
            newState.isLoading = false;
            newState.codeType = 'Creation'; 
            return newState;
        }

        case types.REGISTER_REQUEST: {
            const newState = { ...state };
            newState.isLoading = true;
            newState.codeType = action.payload.codeType;
            return newState;
        }

        case types.VERIFY_REGISTER_CODE_REQUEST: {
            const newState = { ...state };
            newState.isLoading = true;
            return newState;
        }

        case types.VERIFY_RECOVERY_CODE_REQUEST: {
            const newState = { ...state };
            newState.isLoading = true;
            return newState;
        }

        case types.VERIFY_RECOVERY_CODE_SUCCESS: {
            const newState = { ...state };
            newState.isLoading = false;
            newState.recoveryToken = action.payload.recoveryToken;
            return newState;
        }

        case types.VERIFY_RECOVERY_CODE_FAILURE: {
            const newState = { ...state };
            newState.isLoading = false;
            newState.recoveryToken = '';
            return newState;
        }

        case types.PASSWORD_UDDATED_SUCCESS: {
            const newState = { ...state };
            newState.isLoading = false;
            return newState;
        }

        case types.PASSWORD_UDDATED_FAILURE: {
            const newState = { ...state };
            newState.isLoading = false;
            return newState;
        }

        case types.PASSWORD_UDDATE_REQUEST: {
            const newState = { ...state };
            newState.isLoading = true;
            return newState;
        }



        case types.RESENT_REGISTER_CODE: {
            const newState = { ...state };
            newState.isLoading = true;
            return newState;
        }

        case types.SEND_RECOVERY_CODE: {
            const newState = { ...state };
            newState.codeType = action.payload.codeType;
            newState.isLoading = true;
            newState.user.email = action.payload.email;
            return newState;
        }

        case types.SEND_CODE_SUCCESS: {
            const newState = { ...state };
            newState.isLoading = false;
            newState.codeType = action.payload.codeType;
            return newState;
        }

        case types.SEND_CODE_FAILURE: {
            const newState = { ...state };
            newState.isLoading = false;
            return newState;
        }

        default:
            return state;
    }
}