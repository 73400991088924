import { useEffect, useState } from "react";
import ViewTraining from "../../Modal/ViewTraining";
import Title from "./Title/Title";
import { FaEye } from "react-icons/fa6";
import * as workoutActions from "../../../store/modules/workout/actions";
import * as authActions from '../../../store/modules/auth/actions';
import { useDispatch, useSelector } from "react-redux";
import axios from '../../../services/axios';


const ReuseWorkout = ({ next }) => {
  const dispatch = useDispatch();
  const userId = useSelector(state => state.auth.user.id);
  const workoutType = useSelector(state => state.workout.creatingWorkout.type);
  const [workouts, setWorkouts] = useState(useSelector(state => state.workout.userWorkouts));
  const [isOpen, setIsOpen] = useState(false);

  const ClickOpen = (e, workout) => {
    e.stopPropagation();
    dispatch(workoutActions.setCurrentCreatingWorkout(workout));
    setIsOpen(true)
  }

  useEffect(() => {
    if (workouts) return;

    async function getData() {
      try {
        const dataApi = await axios.get(`/workouts/user/${userId}`, userId);
        setWorkouts(dataApi.data);
        dispatch(workoutActions.setUserWorkouts(dataApi.data))
      } catch (err) {
        if (err.response.status === 401) {
          dispatch(authActions.loginFailure());
        }
      }
    }
    getData();
  }, [])

  const handleDays = (wDays) => {
    let days = [];
    wDays.forEach(day => {
      if (day === 0) days.push('Dom');
      if (day === 1) days.push('Seg');
      if (day === 2) days.push('Ter');
      if (day === 3) days.push('Qua');
      if (day === 4) days.push('Qui');
      if (day === 5) days.push('Sex');
      if (day === 6) days.push('Sáb');
    })

    return days
  }

  const handleClickWorkout = (e, index) => {
    const workout = workouts[index];
    workout.name = '';
    workout.date = null;
    workout.days = null;
    workout.type = workoutType;
    dispatch(workoutActions.setCurrentCreatingWorkout(workout))
    next();
  }

  return (
    <>
    <div className="mx-4">
      <Title Title={"Selecione o treino que você quer reutilizar "} />
    </div>
      <div className="mt-11 mx-6">
        {workouts ?
          workouts.map((workout, index) => (
            <div className="effect border-2 border-IconsColor px-6 py-5 my-2 rounded-md w-full flex flex-row text-Poppins text-white justify-between items-center cursor-pointer "
            onClick={(e) => handleClickWorkout(e, index)}
            >
              <h1 className="font-bold text-xl">{workout.name}</h1>
              <p>{workout.date !== 'none' ? workout.date : handleDays(workout.days)}</p>
              <FaEye size={30}
                className=" text-IconsColor active:bg-gray-900"
                onClick={(e) => ClickOpen(e, workout)} />
            </div>
          ))
          : ''
        }
      </div>
      <ViewTraining isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </>
  );
};

export default ReuseWorkout;
