import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from '../../../services/axios';
import history from '../../../services/history';
import * as authActions from '../../../store/modules/auth/actions';
import * as workoutActions from "../../../store/modules/workout/actions";

import BtnTypeTraining from "../../GlobalsButtons/BtnTypeTraining";

const TypeReuse = ({ next }) => {
  const dispatch = useDispatch();
  const userId = useSelector(state => state.auth.user.id);
  const [workouts, setWorkouts] = useState([]);


  useEffect(() => {
    async function getData() {
      try {
        const dataApi = await axios.get(`/workouts/user/${userId}`, userId);
        setWorkouts(dataApi.data);
        dispatch(workoutActions.setUserWorkouts(dataApi.data))
      } catch (err) {
        if (err.response.status === 401) {
          dispatch(authActions.loginFailure());
          history.push('/login');
        }
      }
    }
    getData();
  }, []);

  return (
    <>
      <div className="px-4">
        <BtnTypeTraining
          next={(e) => next(e, 2)}
          Title={"Novo treino"}
          subTitle={"Crie um treino personalizado do zero."}
        />
        {
          workouts.length > 0 ? <BtnTypeTraining
            next={next}
            Title={"Reutilizar treino"}
            subTitle={"Reutilize um treino existente."}
          /> : ''
        }
      </div>
    </>
  );
};

export default TypeReuse;
