import NavBar from "../../components/NavBar/NavBar";
import { Link } from "react-router-dom/cjs/react-router-dom";

import { FaAngleRight } from "react-icons/fa6";
import BtnOrange from "../../components/GlobalsButtons/BtnOrange";

import gato from "../../assets/profile/gato.png";
import semaluno from "../../assets/semaluno.svg";
import { useState } from "react";

const ManageCustomer = () => {
  // Correção: desestruturar o estado e a função para atualizar
  const [hasCustomer, setHasCustomer] = useState(true);

  return (
    <>
      <NavBar />
      <div className="container-reponsive mx-6">
        <h1 className="Title-pages mb-12">Alunos</h1>
        {hasCustomer ? (
          <Link to="/traineemanager">
            <div className="effect border-IconsColor border-solid border-2 px-4 py-2 rounded-xl my-3 mx-2 flex flex-row items-center">
              <img
                src={gato}
                draggable={false}
                className="bg-black w-12 h-12 rounded-full border-4 border-IconsColor mr-4"
              />
              <p className="flex-1 font-bold text-white text-2xl whitespace-nowrap">
                Hugo
              </p>
              <FaAngleRight size={38} className="text-IconsColor pointer" />
            </div>
          </Link>
        ) : (
          <div className="flex flex-col items-center">
            <span className="font-Poppins font-bold text-LaranjaClaro text-xl">
              você ainda não possui alunos
            </span>
            <img src={semaluno} className="mt-4 mb-4" />
            <BtnOrange name="Novo Aluno" />
          </div>
        )}
      </div>
    </>
  );
};

export default ManageCustomer;
