

const Training = ({ Title, numberConcluid, numberPedant }) => {
  return (
    <div className="flex flex-col items-center font-Poppins">
        <div className="mb-6"> 
            <h1>{Title}:</h1>
        </div>
        <div className="flex flex-row justify-center">
            <div className="flex flex-col items-center mx-3">
                <h1 className="font-bold text-xl">{numberConcluid}</h1>
                <p className="text-sm text-IconsColor">Concluído</p>
            </div>
            <div className="flex flex-col items-center mx-3">
                <h1 className="font-bold text-xl">{numberPedant}</h1>
                <p className="text-sm text-IconsColor">Pendente</p>
            </div>
        </div>
    </div>
  )
}

export default Training;