const DayWeek = ({ Day, status }) => {
  return (
    <div>
      {status === 0 ? (
        <div className="flex justify-items-center border-2 border-IconsColor rounded-xl px-3 py-2 cursor-not-allowed opacity-75">
          <h1 className="font-Poppins font-bold text-xl">{Day}</h1>
        </div>
      ) : (
        ""
      )}
      {status === 1 ? (
        <div className="flex justify-items-center border-2 border-orange-800 rounded-xl px-3 py-2 opacity-75">
          <h1 className="font-Poppins font-bold text-xl">{Day}</h1>
        </div>
      ) : (
        ""
      )}
      {status === 2 ? (
        <div className="flex justify-items-center border-2 border-green-600 rounded-xl px-3 py-2 opacity-75">
          <h1 className="font-Poppins font-bold text-xl">{Day}</h1>
        </div>
      ) : (
        ""
      )}
      {status === 3 ? (
        <div className="flex justify-items-center border-2 border-red-700 rounded-xl px-3 py-2 opacity-75">
          <h1 className="font-Poppins font-bold text-xl">{Day}</h1>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default DayWeek;
