import NavBar from "../../components/NavBar/NavBar";
import gato from "../../assets/profile/gato.png";
import DayWeek from "../../components/Personal/Week/DayWeek";
import Training from "../../components/Personal/Trainings/Training";

import { RiArrowRightSLine } from "react-icons/ri";
import { FaUserXmark } from "react-icons/fa6";
import { useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";
import BtnTypeTraining from "../../components/GlobalsButtons/BtnTypeTraining";

const StudentManager = () => {
  const [monday, setMonday] = useState(2);
  const [tuesday, setTuesday] = useState(2);
  const [wednesday, setWednesday] = useState(1);
  const [thursday, setThursday] = useState(1);
  const [friday, setFriday] = useState(0);
  const [saturday, setSaturday] = useState(3);
  const [sunday, setSunday] = useState(3);

  return (
    <>
      <NavBar />
      <div className="container-reponsive mx-6 text-white font-Poppins">
        <div className="flex flex-row items-center my-6">
          <div className="mr-6">
            <img
              src={gato}
              draggable={false}
              className="bg-black w-20 h-20 rounded-full border-4 border-IconsColor"
            />
          </div>
          <div className="flex flex-col">
            <h1 className="text-lg">Pedro</h1>
            <p className="text-IconsColor text-sm">pedro2344458@gmail.com</p>
          </div>
          <div className="flex items-end justify-start pl-6">
            <FaUserXmark
              size={20}
              className="-mt-11 text-red-600 cursor-pointer"
            />
          </div>
        </div>
        <hr className="w-full bg-CinzaInput" />
        <div className="flex flex-row justify-between my-5">
          <DayWeek Day="D" status={sunday} />
          <DayWeek Day="S" status={monday} />
          <DayWeek Day="T" status={tuesday} />
          <DayWeek Day="Q" status={wednesday} />
          <DayWeek Day="Q" status={thursday} />
          <DayWeek Day="S" status={friday} />
          <DayWeek Day="S" status={saturday} />
        </div>
        <div className="flex flex-row justify-between">
          <Training
            Title={"Treinos fixos"}
            numberConcluid={"09"}
            numberPedant={"05"}
          />
          <Training
            Title={"Treinos únicos"}
            numberConcluid={"00"}
            numberPedant={"07"}
          />
        </div>
        <Link>
          <div className="flex flex-row justify-between items-center my-5 text-blue-500 cursor-pointer border-b-2 border-blue-500">
            <p className="text-base">Treinos existentes</p>
            <RiArrowRightSLine size={30} />
          </div>
        </Link>
        <div className="flex flex-col">
          <h1 className="font-bold text-xl my-5">Novo treino</h1>
          <BtnTypeTraining Title={"Fixos"} subTitle={"Crie seu treino diário escolhendo o dia que deseja treinar."} next={""} />
          <BtnTypeTraining Title={"Único"} subTitle={"Crie um treino avulso para ser realizado apenas uma vez."} next={""} />
        </div>
      </div>
    </>
  );
};

export default StudentManager;
