import NavBar from "../components/NavBar/NavBar";
import { Link } from "react-router-dom";
import TrainingButtons from "../components/GlobalsButtons/TrainingButtons";
import { FaAngleRight } from "react-icons/fa6";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from '../services/axios';
import * as authActions from '../store/modules/auth/actions';
import * as workoutActions from "../store/modules/workout/actions";

const Home = () => {
  const dispatch = useDispatch();
  const userId = useSelector(state => state.auth.user.id);
  const isLoggedIn = useSelector(state => state.auth.user.isLoggedIn)

  //const [isPersonalPerfil, setisPersonalPerfil] = useState(false);

  const [workouts, setWorkouts] = useState([]);

  useEffect(() => {
      async function getData() {
        try {
          const dataApi = await axios.get(`/workouts/user/${userId}`, userId);
          setWorkouts(dataApi.data);
          dispatch(workoutActions.setUserWorkouts(dataApi.data))
        } catch (err) {
          if (err.response.status === 401) {
            dispatch(authActions.loginFailure());
          }
        }
      }
      getData();
  }, []);

  return (
    <>
      <NavBar />
      <div className="container-reponsive mt-5 mx-6">
        <Link to="/createworkout">
          <TrainingButtons name={"Novo Treino"} />
        </Link>
        <Link to="/myworkouts">
          <TrainingButtons name={"Meus Treinos"} />
        </Link>
        <div className="mt-12">
          <p className="font-Poppins text-LaranjaClaro mb-2 ml-5 text-xl">
            Treinos pendentes
          </p>

          {workouts.map((workout, index) => (
            workout.status == 'Pending' ?
              <Link to={`workout/${workout._id}`} key={index}>
                <div className=" overflow-y-scroll overflow-x-hidden max-h-64 scrollbar-custom w-full px-2">
                  <div className=" effect hover:scale-100 border-IconsColor border-solid border-2 p-4 rounded-xl my-3 flex flex-row items-center">
                    <p className=" flex-1 font-bold text-white text-lg ">
                      {workout.name}
                    </p>
                    <FaAngleRight size={38} className="text-IconsColor pointer" />
                  </div>
                </div>
              </Link> : false
          ))}
          <div className="mt-4">
          
          </div>
        </div>
      </div>
    </>
  )

};

export default Home;
